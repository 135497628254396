import React, {useEffect} from 'react'
import {compose} from 'recompose';
import {inject,observer} from 'mobx-react'
import { Router, Link } from "@reach/router"
import DealPage from '@src/components/templates/DealPage'
import withRoot from '../withRoot'


const Deal = (props)=> {
    const {uiStore,appStore} = props

    return (
        <div style={{position:'relative'}}>
            <Router basepath='/deal/:id'>
                <DealPage path="/"  />
            </Router>
        </div>
        )
}

export default compose(withRoot,inject('uiStore','appStore'),observer)(Deal)
